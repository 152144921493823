.burger{
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767.98px){
  .burger{
    height: 20px;
    width: 20px;
  }
}